<template>
  <ul class="grid t3">
    <li>
      <div :id="id" class="form_wrap t2">
        <span v-if="isExistFormTit" class="form_tit">{{ title }}</span>
        <!--0826 수정 시작-->
        <input
          class="wid120 inp_cal"
          type="text"
          :placeholder="$t('comp.012')"
          :ref="`${compId}-dp`"
          :name="`${compId}-sdt`"
          v-model="formattedSdt"
          @input="isNumberCheck"
          @change="changeDt"
          autocomplete="off"
          @keydown="keyinput"
          maxlength="10"
        />
        <div class="input_calendar inline">
          <input
            class="inp_cal"
            type="text"
            :name="`${compId}-sdt`"
            :placeholder="$t('comp.013')"
            v-model="formattedEdt"
            @input="isNumberCheck"
            @click="showRangePicker"
            @change="changeDt"
            @keydown="keyinput"
            autocomplete="off"
            maxlength="10"
          />
          <button class="tbl_icon calendar" @mousedown.stop.prevent @click.stop.prevent="toggleRangePicker">{{ $t('comp.014') }}</button>
        </div>
        <!--0826 수정 끝-->
      </div>
    </li>
    <li class="btn_rdo">
      <input type="radio" :ref="`${compId}-rdo1`" :id="`${compId}-rdo1`" name="rdo" checked>
      <label :for="`${compId}-rdo1`">{{ $t('comp.102') }}</label>
    </li>
    <li class="btn_rdo">
      <input type="radio" :ref="`${compId}-rdo2`" :id="`${compId}-rdo2`" name="rdo" @click="selOneWeek">
      <label :for="`${compId}-rdo2`">{{ $t('comp.103') }}</label>
    </li>
    <li class="btn_rdo">
      <input type="radio" :ref="`${compId}-rdo3`" :id="`${compId}-rdo3`" name="rdo" @click="selOneMonth">
      <label :for="`${compId}-rdo3`">{{ $t('comp.104') }}</label>
    </li>
    <li class="btn_rdo">
      <input type="radio" :ref="`${compId}-rdo4`" :id="`${compId}-rdo4`" name="rdo" @click="selMonth">
      <label :for="`${compId}-rdo4`">{{ $t('comp.105') }}</label>
      <div class="impp" v-show="showMp">
        <div ref="mp" class="imp"></div>
      </div>
    </li>
  </ul>
</template>

<script>
import moment from 'moment'

export default {
  name: 'DateRangePicker',

  props: {
    title: { type: String, default: '', required: false },
    sdate: { type: String, default: '', required: false },
    edate: { type: String, default: '', required: false },
    isDebug: { type: Boolean, default: false, required: false },
    id: { type: String, default: '', required: false },
    isExistFormTit: { type: Boolean, default: true, required: false }
  },

  data () {
    return {
      compId: this.$uuid.v1(),
      sdt: this.sdate,
      edt: this.edate,
      format: 'YYYY.MM.DD',
      show: false,
      showMp: false
    }
  },

  computed: {
    formattedSdt: {
      set (v) {
        this.sdt = v.replace(/[^\d]/g, '')
      },

      get () {
        return this.sdt.replace(/(\d{4})(\d{1,2}(?=\d{2})|\d{1,2}(?=\d{1}))(\d{1,2}).*/, '$1.$2.$3')
      }
    },

    formattedEdt: {
      set (v) {
        this.edt = v.replace(/[^\d]/g, '')
      },

      get () {
        return this.edt.replace(/(\d{4})(\d{1,2}(?=\d{2})|\d{1,2}(?=\d{1}))(\d{1,2}).*/, '$1.$2.$3')
      }
    }
  },

  watch: {
    sdate () {
      this.sdt = this.sdate
      this.setDatePick()
    },
    edate () {
      this.edt = this.edate
      this.setDatePick()
    },
    showMp () {
      if (this.showMp) {
        this.$refs.mp.addEventListener('click', this.stopProp)
        document.body.addEventListener('click', this.selMonth)
      } else {
        this.$refs.mp.removeEventListener('click', this.stopProp)
        document.body.removeEventListener('click', this.selMonth)
      }
    }
  },

  mounted () {
    $(this.$refs[this.compId + '-dp']).daterangepicker({
      autoUpdateInput: false,
      showDropdowns: true,
      autoApply: true,
      locale: {
        format: this.format
      }
    }, (s, e, l) => {
      this.formattedSdt = s.format(this.format)
      this.formattedEdt = e.format(this.format)
      this.emitChange()

      this.$refs[this.compId + '-rdo1'].checked = true
    })
    .on('show.daterangepicker', () => {
      this.setDatePick()
      this.show = true
    })
    .on('hide.daterangepicker', () => {
      this.show = false
    })

    $(this.$refs.mp).MonthPicker({
      ShowIcon: false,
      MonthFormat: 'yy.mm',
      i18n: {
        year: this.$t('comp.009'),
        months: [
          `${this.$t('comp.1_month')}`,
          `${this.$t('comp.2_month')}`,
          `${this.$t('comp.3_month')}`,
          `${this.$t('comp.4_month')}`,
          `${this.$t('comp.5_month')}`,
          `${this.$t('comp.6_month')}`,
          `${this.$t('comp.7_month')}`,
          `${this.$t('comp.8_month')}`,
          `${this.$t('comp.9_month')}`,
          `${this.$t('comp.10_month')}`,
          `${this.$t('comp.11_month')}`,
          `${this.$t('comp.12_month')}`
        ]
      },
      OnAfterChooseMonth: (v) => {
        const m = moment(v)
        const symd = m.startOf('month').format(this.format)
        const eymd = m.endOf('month').format(this.format)

        this.setFromToDate(symd, eymd)
        this.showMp = false
      }
    })
    this.setDatePick()
  },

  methods: {
    keyinput (e) {
      const dp = $(this.$refs[this.compId + '-dp']).data('daterangepicker')
      dp.clickCancel()
      dp.hide()
    },

    toggleRangePicker (e) {
      const dp = $(this.$refs[this.compId + '-dp']).data('daterangepicker')
      if (this.show) dp.hide()
      else dp.show()
    },

    showRangePicker () {
      const dp = $(this.$refs[this.compId + '-dp']).data('daterangepicker')
      dp.show()
    },

    setDatePick () {
      const format = 'YYYYMMDD'
      const dp = $(this.$refs[this.compId + '-dp']).data('daterangepicker')

      if (this.sdt && this.edt) {
        const smo = moment(this.sdt, format)
        if (smo.isValid()) dp.setStartDate(smo.toDate())

        const emo = moment(this.edt, format)
        if (emo.isValid()) dp.setEndDate(emo.toDate())
      }
    },

    emitChange () {
      this.$emit('change', this.sdt, this.edt)
    },

    isNumberCheck (e) {
      //아예 한글 영문 입력 안되게 해달라고 해서 추가.
      //입력시 버튼이 무조건 직접입력에 focus가 되어 있어야 한다.
      const t = e.target
      const n = t.name
      const value = t.value.replace(/[^0-9]/gi, '')
      e.target.value = value
      if (n === this.$refs[this.compId + '-sdt']) {
        this.formattedSdt = value
      }

      if (n === this.$refs[this.compId + '-edt']) {
        this.formattedEdt = value
      }
      this.$refs[this.compId + '-rdo1'].checked = true
    },

    changeDt (e, type) {
      // e.preventDefault() // Enter Key 클릭시 daterangepicker 에서 호출하는 callback 차단(2번 호출 방지용)
      const t = e.target
      const n = t.name
      const m = t.value.match(/(\d+)\.(\d+)\.(\d+)/)
      t.value = t.oldValue ?? ''

      if (m) {
        const mo = moment({
          year: parseInt(m[1]),
          month: parseInt(m[2]) - 1,
          day: parseInt(m[3])
        })

        if (mo.isValid()) {
          t.value = mo.format(this.format)
          this.setDatePick()
          t.oldValue = t.value
        }
      }

      if (n === this.$refs[this.compId + '-sdt']) {
        this.formattedSdt = t.value
      }

      if (n === this.$refs[this.compId + '-edt']) {
        this.formattedEdt = t.value
      }

      this.emitChange()
    },

    setFromToDate (sdt, edt) {
      this.formattedSdt = sdt
      this.formattedEdt = edt
      this.emitChange()
    },

    selOneWeek () {
      const now = moment()
      const edt = now.format(this.format)
      const sdt = now.add(-6, 'd').format(this.format)

      this.setFromToDate(sdt, edt)
    },

    selOneMonth () {
      const now = moment()
      const edt = now.format(this.format)
      const sdt = now.add(-1, 'M').add(1, 'd').format(this.format)

      this.setFromToDate(sdt, edt)
    },

    selMonth (e) {
      e.stopPropagation()
      this.showMp = !this.showMp
    },

    stopProp (e) {
      e.stopPropagation()
    }
  }
}
</script>

<style scoped>
.input_calendar.mid, .input_calendar.mid input {
    width: 200px;
}
div.input_error .inp_cal {
  border: 1px solid #f2084f !important;
}
.impp {
  position: absolute;
}
.imp {
  position: relative;
  top: 0px;
  left: -220px;
  z-index: 99999;
}
</style>
<style>
.month-picker-month-table .ui-button{
  width: 4.3em !important;
}
</style>
